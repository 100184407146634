<template>
  <my-drawer ref="nameDrawer" title="里程碑名称" :transfer="false">
    <template v-slot:main>
      <div style="display:flex;flex-direction:column;">
        <my-input ref="formName" title="名称" v-model="name" :validate="nameValidate" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width:100px;" @click="submit">保存</Button>
    </template>
  </my-drawer>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
  },
  data() {
    return {
      milestone_id: 0,
      name: "",
    };
  },
  methods: {
    ...mapActions({
      milestoneSaveNameAction: "milestoneSaveName",
    }),
    open(name, milestone_id) {
      this.milestone_id = milestone_id;
      this.name = name;
      this.$refs.nameDrawer.open();
    },
    close(){
       this.$refs.nameDrawer.close();
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.milestoneSaveNameAction({
            employer_id: localStorage.getItem("employer_id"),
            milestone_id: this.milestone_id,
            name: this.name,
          })
            .then(() => {
              this.$emit('refreshInfo')
              this.$refs.nameDrawer.close();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.name == "") {
          reject("请填写里程碑名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.board-form-image {
  width: 100px;
  height: 118px;
}
</style>