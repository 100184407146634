<template>
  <div class="pay-item-root-container">
    <div class="pay-item-cell">
      <div class="pay-item-row">
        <img :src="icons.milestoneType" style="width:32ox;height:37px;" />
        <div class="pay-item-column" style="margin-left:10px;">
          <div class="pay-item-row" style="margin-bottom:5px;">
            <span style="font-size: 13px;margin-right:10px;">付款列表</span>
            <span class="pay-count">{{dataSource.count}}笔</span>
          </div>
          <span style="font-size: 12px;color:#707070">ID:{{dataSource.milestone_id}}</span>
        </div>
      </div>
    </div>
    <div class="pay-item-cell">
      <span style="font-size: 13px;font-weight:bold;">{{dataSource.name}}</span>
    </div>
    <div class="pay-item-cell">
      <div class="pay-item-row">
        <my-avatar :size="40" :name="dataSource.create_user_name" :img_url="dataSource.create_user_img_url" />
        <div class="pay-item-column" style="margin-left:10px;">
          <span style="font-size: 13px;font-weight:bold;margin-bottom:10px;">{{dataSource.create_user_name}}</span>
          <span style="font-size: 12px;color:#707070">创建于{{dataSource.create_time}}</span>
        </div>
      </div>
    </div>
    <div class="pay-item-cell">
      <span style="font-size:12px;">{{dataSource.state_format}}</span>
    </div>
    <div class="pay-item-cell">
      <div class="pay-item-fee-container">
        <div class="pay-item-row">
          <span style="font-size:14px;font-weight:bold">合计</span>
          <fee :amount="dataSource.total_amount" :primarySize="14" :secondarySize="12" />
        </div>
        <span style="font-size:12px;color:#707070;text-align: right;">最新更新于：{{dataSource.update_time}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
import Fee from "../../../components/Fee";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "my-avatar": MyAvatar,
    fee: Fee,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.pay-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 0 5px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.pay-item-cell {
  width: 20%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pay-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.pay-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pay-item-column {
  display: flex;
  flex-direction: column;
}
.pay-item-fee-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.pay-count {
  height: 16px;
  font-size: 12px;
  line-height: 12px;
  padding: 2px 15px;
  min-width: 50px;
  border-radius: 8px;
  background-color: #616161;
  color: white;
}
</style>