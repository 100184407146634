<template>
  <Modal
    v-model="isShowModal"
    footer-hide
    :closable="false"
    :width="1200"
    @on-visible-change="onVisibleChange"
  >
    <div v-if="isShowModal" class="milestone-detail-modal-root-container">
      <div class="milestone-detail-modal-head-container">
        <span style="font-size: 12px; font-weight: bold; color: #7d7d7d"
          >里程碑事件ID：{{ milestoneDetail.milestone_id }}</span
        >
        <div style="display: flex; flex-direction: row; align-items: center">
          <span style="font-size: 12px; font-weight: bold; color: #424648"
            >当前状态：{{ milestoneDetail.state_format }}
          </span>
          <Icon
            v-if="milestoneDetail.state == 1"
            type="ios-trash"
            size="28"
            style="cursor: pointer; margin-left: 20px"
            @click="deleteSelf"
          />
        </div>
      </div>
      <div class="milestone-detail-modal-body-container">
        <div class="milestone-detail-main-container">
          <project-info
            :dataSource="projectDetail"
            :id="milestoneDetail.milestone_id"
            :type="1"
          />
          <excel-reference :data-source="excels" />
        </div>
        <div class="milestone-detail-main-container" style="margin: 0px 50px">
          <div class="milestone-detail-modal-row" style="margin-bottom: 30px">
            <span
              style="
                font-size: 23px;
                font-weight: bold;
                color: #000000;
                max-width: 300px;
              "
              >{{ milestoneInfo.name }}</span
            >
            <img
              :src="icons.edit"
              style="width: 18px; height: 18px; cursor: pointer"
              @click="editName"
            />
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">里程碑事件ID</span>
            <span class="milestone-detail-modal-row-val">{{
              milestoneInfo.milestone_id
            }}</span>
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">创建时间</span>
            <span class="milestone-detail-modal-row-val">{{
              milestoneInfo.create_time
            }}</span>
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">创建者</span>
            <span class="milestone-detail-modal-row-val">{{
              milestoneInfo.create_user_name
            }}</span>
          </div>
          <div class="milestone-detail-modal-row" style="margin-bottom: 30px">
            <span class="milestone-detail-modal-row-key">关联的项目</span>
            <span class="milestone-detail-modal-row-val">{{
              milestoneInfo.project_name
            }}</span>
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">创建者角色</span>
            <span class="milestone-detail-modal-row-val">雇主</span>
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">创建方式</span>
            <span class="milestone-detail-modal-row-val"
              >使用Excel文件导入</span
            >
          </div>
          <div class="milestone-detail-modal-row" style="margin-bottom: 20px">
            <span class="milestone-detail-modal-row-key">最近更新于</span>
            <span class="milestone-detail-modal-row-val">{{
              milestoneInfo.update_time
            }}</span>
          </div>
          <div class="milestone-detail-modal-row">
            <span class="milestone-detail-modal-row-key">备注信息</span>
            <span
              @click="updateMore"
              style="
                font-size: 12px;
                font-weight: 500;
                color: #3b73bb;
                cursor: pointer;
              "
              >更新</span
            >
          </div>
          <div class="milestone-detail-more">{{ milestoneInfo.more }}</div>
        </div>
        <div class="milestone-detail-main-container">
          <Button @click="edit" type="primary" style="margin-bottom: 30px"
            >编辑</Button
          >
          <accessory-reference :data-source="accessories" @upload="upload" />
        </div>
      </div>

      <milestone-name-drawer
        @refreshInfo="refreshInfo"
        ref="milestoneNameDrawer"
      />
      <milestone-more-drawer
        @refreshInfo="refreshInfo"
        ref="milestoneMoreDrawer"
      />
      <accessories-drawer ref="accessoriesDrawer" @add="add" />
      <new-milestone-modal ref="newMilestoneModal" />
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../../common/icon";
import ProjectInfo from "../../../components/Panel/ProjectInfo";
import ExcelReference from "../../../components/Panel/ExcelReference";
import AccessoryReference from "../../../components/Panel/AccessoryReference";
import MilestoneNameDrawer from "../Drawer/MilestoneNameDrawer";
import MilestoneMoreDrawer from "../Drawer/MilestoneMoreDrawer";
import AccessoriesDrawer from "../../../components/Drawer/AccessoriesDrawer";
import NewMilestoneModal from "../Modal/NewMilestoneModal";
import moment from "moment";
export default {
  components: {
    "project-info": ProjectInfo,
    "excel-reference": ExcelReference,
    "accessory-reference": AccessoryReference,
    "milestone-name-drawer": MilestoneNameDrawer,
    "milestone-more-drawer": MilestoneMoreDrawer,
    "accessories-drawer": AccessoriesDrawer,
    "new-milestone-modal": NewMilestoneModal,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,

      projectId: 0,
      projectDetail: {
        client_user: [],
      },
      milestoneInfo: {},
      excels: [],
      accessories: [],

      milestoneDetail: {}, // 传入的
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
      milestoneGetDetailAction: "milestoneGetDetail",
      milestoneGetExcelListAction: "milestoneGetExcelList",
      milestoneGetUserListAction: "milestoneGetUserList",
      milestoneGetAccessoriesListAction: "milestoneGetAccessoriesList",
      milestoneAddAccessoriesAction: "milestoneAddAccessories",
      milestoneDelAction: "milestoneDel",
    }),
    deleteSelf() {
      this.milestoneDelAction({
        employer_id: localStorage.getItem("employer_id"),
        milestone_id: this.milestoneDetail.milestone_id,
      })
        .then((res) => {
          this.$Message.success("删除成功");
          this.$emit("refresh");
          this.isShowModal = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    open(milestone) {
      this.milestoneDetail = milestone;

      Promise.all([
        this.milestoneGetDetailAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id: this.milestoneDetail.milestone_id,
        }),
        this.milestoneGetUserListAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id: this.milestoneDetail.milestone_id,
        }),
        this.milestoneGetExcelListAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id: this.milestoneDetail.milestone_id,
        }),
        this.milestoneGetAccessoriesListAction({
          employer_id: localStorage.getItem("employer_id"),
          milestone_id: this.milestoneDetail.milestone_id,
        }),
      ])
        .then((values) => {
          this.milestoneInfo = values[0];
          this.milestoneInfo.create_time = moment(values[0].create_time).format(
            "YYYY/MM/DD HH:mm"
          );
          this.milestoneInfo.update_time = moment(values[0].update_time).format(
            "YYYY/MM/DD HH:mm"
          );
          this.projectDetail = values[1];
          for (let item of values[2]) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.excels = values[2];
          for (let item of values[3]) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.accessories = values[3];
          this.isShowModal = true;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    editName() {
      this.$refs.milestoneNameDrawer.open(
        this.milestoneInfo.name,
        this.milestoneInfo.milestone_id
      );
    },
    updateMore() {
      this.$refs.milestoneMoreDrawer.open(
        this.milestoneInfo.more,
        this.milestoneInfo.milestone_id
      );
    },
    refreshInfo() {
      this.milestoneGetDetailAction({
        employer_id: localStorage.getItem("employer_id"),
        milestone_id: this.milestoneDetail.milestone_id,
      })
        .then((res) => {
          this.milestoneInfo = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    add(acc) {
      this.milestoneAddAccessoriesAction({
        employer_id: localStorage.getItem("employer_id"),
        milestone_id: this.milestoneDetail.milestone_id,
        file_name: acc.file_name,
        file_url: acc.file_url,
        size: acc.size,
      })
        .then(() => {
          this.milestoneGetAccessoriesListAction({
            employer_id: localStorage.getItem("employer_id"),
            milestone_id: this.milestoneDetail.milestone_id,
          })
            .then((res) => {
              for (let item of res) {
                item.create_time = moment(item.create_time).format(
                  "YYYY/MM/DD HH:mm"
                );
              }
              this.accessories = res;
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    edit() {
      this.$refs.newMilestoneModal.open(this.milestoneDetail.milestone_id);
    },
    onVisibleChange(flag) {
      // 当 modal 关闭时自动隐藏 drawer
      if (!flag) {
        this.$refs.milestoneNameDrawer &&
          this.$refs.milestoneNameDrawer.close();
        this.$refs.milestoneMoreDrawer &&
          this.$refs.milestoneMoreDrawer.close();
      }
    },
  },
};
</script>

<style scoped>
.milestone-detail-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 700px;
  overflow: hidden;
}
.milestone-detail-modal-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
}
.milestone-detail-modal-body-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}
.milestone-detail-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.milestone-detail-modal-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.milestone-detail-modal-row-key {
  font-size: 12px;
  font-weight: 500;
  color: #7d7d7d;
}
.milestone-detail-modal-row-val {
  font-size: 12px;
  font-weight: 500;
  color: #000000;
}
.milestone-detail-more {
  flex: 1;
  font-size: 14px;
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 10px;
}
</style>