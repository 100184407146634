<template>
  <div class="milestone-root-container">
    <slide-tabbar :tabs="tabs" @on-change="onTabChange" show-bar></slide-tabbar>
    <div v-if="currentTab == 0" style="overflow: hidden; display: flex">
      <div class="milestone-table-container">
        <div class="milestone-table-header-container">
          <span style="width: 20%; text-align: center; font-weight: bold"
            >事件类型</span
          >
          <span style="width: 20%; text-align: center; font-weight: bold"
            >名称</span
          >
          <span style="width: 20%; text-align: center; font-weight: bold"
            >创建者</span
          >
          <span style="width: 20%; text-align: center; font-weight: bold"
            >当前状态</span
          >
          <span style="width: 20%; text-align: center; font-weight: bold"
            >包含的内容</span
          >
        </div>
        <div class="milestone-item-container">
          <milestone-item
            v-for="item in milestoneList"
            :key="item.id"
            :data-source="item"
            @click.native="showDetailModal(item)"
          />
        </div>
      </div>
    </div>
    <div v-if="currentTab == 1">
      <tip-board
        style="margin-top: 50px"
        title="Ooh,没有查询到已完成的任务"
        sub-title="根据您输入的搜索条件，没有为您找到任何交易。请尝试更换搜索条件"
        :source="icons.notFound"
      />
    </div>

    <milestone-detail-modal ref="milestoneDetailModal" @refresh="refresh" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import SlideTabbar from "../../components/SlideTabbar";
import TipBoard from "../../components/TipBoard";
import MilestoneItem from "./Item/MilestoneItem";
import MilestoneDetailModal from "./Modal/MilestoneDetailModal";
import moment from "moment";
export default {
  components: {
    "slide-tabbar": SlideTabbar,
    "tip-board": TipBoard,
    "milestone-item": MilestoneItem,
    "milestone-detail-modal": MilestoneDetailModal,
  },
  data() {
    return {
      icons: icon,
      projectId: 0,
      currentTab: 0,
      tabs: [
        {
          name: "我创建的",
        },
        {
          name: "我收到的",
        },
      ],
      milestoneList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.refresh();
  },
  methods: {
    ...mapActions({
      milestoneGetListAction: "milestoneGetList",
    }),
    onTabChange(index) {
      this.currentTab = index;
    },
    showDetailModal(item) {
      this.$refs.milestoneDetailModal.open(item);
    },
    refresh() {
      this.milestoneGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
            item.update_time = moment(item.update_time).format(
              "YYYY/MM/DD HH:mm"
            );
            switch (item.state) {
              case 1:
                item.state_format = "创建完成/已发送";
                break;
              case 2:
                item.state_format = "已引用至付款";
                break;
              case 3:
                item.state_format = "已完成支付";
                break;
            }
          }
          this.milestoneList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.milestone-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: 150px;
  overflow: hidden;
}
.milestone-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.milestone-table-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.milestone-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>